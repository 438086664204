html {
    scroll-padding-top: 100px;
}

.sidebar-navigation {
    position: fixed;
    top: 120px;
    bottom: 85px;
    width: 350px;
    padding: 3rem;
    background-color: #f6f6f6;
    overflow-x: hidden;
    -webkit-transition: all .5s;
    transition: all .5s
}

.sidebar-navigation.stick-to-top {
    top: 40px
}

.sidebar-navigation.show {
    -webkit-transform: translateX(0);
    transform: translateX(0)
}

.doc-nav {
    padding-left: 0;
    list-style: none
}

.doc-nav>li {
    margin-bottom: 1.5rem
}

.doc-nav ul {
    padding-left: 3rem;
    list-style: none
}

.doc-nav ol {
    padding-left: 1.3rem
}

.doc-nav a {
    display: block;
    font-size: 16px;
    color: var(--typo-body);
    text-decoration: none;
    padding: 0
}

.doc-nav a.active,
.doc-nav a:hover {
    color: var(--action-color)
}

.data_main {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    max-width: 100%;
    margin-left: 410px;
    padding: 0 0 30px 30px
}


.section-1,
.section-2 {
    height: 2000px
}


.data_main h3 {
    border-bottom: 1px solid #eee;
    padding-bottom: 1rem;
    /*margin-bottom: 3rem;*/
    /*margin-top: 5rem;*/
    -webkit-transition: color .5s;
    transition: color .5s
}

.data_main h4 {
    margin: 0 0 2rem
}

.data_main p {
    margin-bottom: 2rem
}

.data_main ul,
.data_main ol {
    margin: 0 0 2rem;
    padding-left: 30px;
}

.data_main img {
    margin-bottom: 3rem;
    -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1)
}




.doc-nav-main {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

section.active h3 {
    color: var(--action-color)
}

.sidebar-navigation ol {
    counter-reset: section;
    list-style-type: none
}

.sidebar-navigation ol li a::before {
    counter-increment: section;
    content: counters(section, ".") ". "
}
.doc-nav-link:hover {
    text-decoration: underline
}


.sidebar-navigation .logo-wrapper img{
    max-height: 50px;
}

pre {
    text-align: left;
    white-space: pre-line;
    position: relative;
    padding: 5px 20px;
    background-color: #f5fcff;
    margin: 15px 0px 40px;

}
code {
    font-weight: 600;
    line-height: 1.5;
}


.kc-mb-wrap {
    width: 300px;
    margin: 20px auto;
    padding: 10px;
    position: relative;
}

.kc-mb-wrap p{
    margin: 0;
    padding: 0;
}

.kc-mb-wrap blockquote {
    margin: 0;
    padding: 0;
    position: relative;
}

.kc-mb-style-3 {
    width: 550px;
}

.kc-mb-style-3 blockquote{
    background: #fff;
    padding: 30px;
    border-radius: 20px;
    box-shadow:
            inset 0 2px 0 rgba(250, 220, 198, 0.7),
            -5px -4px 25px rgba(0, 0, 0, 0.3);
}

.kc-mb-style-3 blockquote:after,
.kc-mb-style-3 blockquote:before  {
    top: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.kc-mb-style-3 blockquote:after  {
    border-top-color: #ffffff;
    border-width: 10px;
    left: 65%;
    margin-left: -10px;
}

.kc-mb-style-3 blockquote:before {
    border-top-color: rgba(0,0,0,0.01);
    border-width: 11px;
    left: 65%;
    margin-left: -11px;
}

.kc-mb-style-3 blockquote p {
    /*font-family: 'Alegreya', serif;*/
    font-size: 14px;
    color: #000000;
    font-weight: bold;
    line-height: 20px;
    font-style: italic;
    text-indent: 100px;
    position: relative;
}

.kc-mb-style-3 blockquote p:before{
    content: '\201C';
    font-family: serif;
    font-style: normal;
    font-weight: 700;
    position: absolute;
    font-size: 135px;
    top: 0px;
    left: -105px;
    color:#FFDC60;
    text-shadow: 7px 14px 10px rgba(0, 0, 0, 0.1);
}



.kc-mb-style-2 {
    width: 550px;
}

.kc-mb-style-2 blockquote{
    background: #fff;
    padding: 30px;
    border-radius: 10px;
    box-shadow:
            inset 0 2px 0 rgba(250, 220, 198, 0.7),
            -5px -4px 25px rgba(0, 0, 0, 0.3);
}

.kc-mb-style-2 blockquote:after,
.kc-mb-style-2 blockquote:before  {
    top: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.kc-mb-style-2 blockquote:after  {
    border-top-color: #ffffff;
    border-width: 10px;
    left: 25%;
    margin-left: -10px;
}

.kc-mb-style-2 blockquote:before {
    border-top-color: rgba(0,0,0,0.01);
    border-width: 11px;
    left: 25%;
    margin-left: -11px;
}

.kc-mb-style-2 blockquote p {
    /*font-family: 'Alegreya', serif;*/
    font-size: 14px;
    color: #000000;
    font-weight: bold;
    line-height: 20px;
    font-style: italic;
    text-indent: 100px;
    position: relative;
}

.kc-mb-style-2 blockquote p:before{
    content: '\201C';
    font-family: serif;
    font-style: normal;
    font-weight: 700;
    position: absolute;
    font-size: 135px;
    top: 0px;
    left: -105px;
    color: #b8b6ff;
    text-shadow: 7px 14px 10px rgba(0, 0, 0, 0.1);
}




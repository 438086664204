@import url('pretendard.css');
@import url('pretendard-subset.css');

html:lang(ko){
    word-wrap: break-word;
}

html:lang(ko){
    word-break: keep-all;
}

.kc-main-menu-animation-off {
    animation: none !important;
}

.kc-logo-width_187 {
    width: 187px;
}

.kc-title {
    font-size: 40px;
    line-height: 1.5;
    font-weight: 700;
    color: var(--color-dark);
}

.kc-axil-btn {
    box-shadow: 0 0px 0px 0px #5956e9 !important;
    font-weight: bold !important;
}

.kc-axil-btn-2 {
    padding: 10px 26px !important;
    border-radius: 10px;
}



.kc-text-color-black {
    color: var(--color-text-dark);
}
.kc-thumbnail{
    overflow: hidden;
    border-radius: 0px;
    position: relative;
}

.kc-font-color-white {
    color: white !important;
}

.kc-font-color-black {
    color: black !important;
}

.kc-address-color {
    color: #b8b8b8;
    margin: 10px 0 0 0;
    font-size: 13px;
}

.kc-address-number {
    color: #c1c1c1;
    font-weight: bold;
}

.kc-footer-social-icon {
    margin: 5px
}
.kc-title-color-blue {
    color: var(--color-primary)
}

.kc-bg-color-blue-text-white {
    background-color: var(--color-primary);
    color: var(--color-white);
}

.kc-text-color-konacard-blue {
    color: #6862e1;
}

.kc-highlight-red {
    border-radius: 1em 0 1em 0;
    background-image: linear-gradient(
            -100deg,
            rgba(255, 20, 0, 0.2),
            rgba(255, 20, 0, 0.7) 95%,
            rgba(255, 20, 0, 0.1)
    );
}
.kc-highlight-yellow {
    /*border-radius: 1em 0 1em 0;*/
    /*background-image: linear-gradient(*/
    /*        -100deg,*/
    /*        rgba(255, 224, 0, 0.2),*/
    /*        rgba(255, 224, 0, 0.7) 95%,*/
    /*        rgba(255, 224, 0, 0.1)*/
    /*);*/
    font-weight: bold;
}
.kc-highlight-green {
    border-radius: 1em 0 1em 0;
    background-image: linear-gradient(
            -100deg,
            rgba(0, 255, 20, 0.2),
            rgba(0, 255, 20, 0.7) 95%,
            rgba(0, 255, 20, 0.1)
    );
}
.kc-highlight-blue {
    border-radius: 1em 0 1em 0;
    background-image: linear-gradient(
            -100deg,
            rgba(0, 20, 255, 0.2),
            rgba(0, 20, 255, 0.7) 95%,
            rgba(0, 20, 255, 0.1)
    );
}

.kc-box-card-data-p{
    -webkit-line-clamp:10!important;
}

.kc-center-div-p-50 {
    margin: auto;
    width: 50%;
    text-align: center;
}

.kc-center-div-p-90 {
    margin: auto;
    width: 90%;
    text-align: center;
}

.kc-center-div-p-25 {
    margin: auto;
    width: 25%;
    text-align: center;
}

.kc-footer-inquiry-btn {
    margin: auto;
    text-align: center;
}

.kc-vertical-center {
    margin: auto;
}

.kc-center-align {
    margin: auto;
    text-align: center;
}

.kc-button-1 {
    padding: 5px 15px;
    border-radius: 5px;
    margin-bottom:-175px;
    background-color: white !important;
    color: var(--color-black) !important;
}

.kc-button-2 {
    padding: 5px 15px;
    border-radius: 5px;
    margin-bottom:-175px;
    margin-left: 5px;
    background-color: white !important;
    color: var(--color-black) !important;
}

.kc-button-1:hover{
    background-color: white !important;
    color: var(--color-white) !important;
}
.kc-button-2:hover{
    background-color: white !important;
    color: var(--color-white) !important;
}

.pt--40 {
    padding-top: 40px;
}

.pt--60 {
    padding-top: 60px;
}

.pb--60 {
    padding-bottom: 60px;
}

.kc-pt--235 {
    padding: 235px 0px 0px;
}

.kc-pt--210 {
    padding-top: 210px !important;
}

.kc-pt--150 {
    padding-top: 150px !important;
}
.kc-pl--20 {
    padding-left: 20px !important;
}
.kc-pr--20 {
    padding-right: 20px !important;
}
.kc-pt--100 {
    padding-top: 100px !important;
}
.kc-pb--100 {
    padding-bottom: 100px !important;
}
.kc-pb--30{
    padding-bottom: 30px !important;
}
.kc-pt--30{
    padding-top: 30px !important;
}

.kc-pt--90 {
    padding-top: 90px;
}

.pl--0 {
    padding-left: 0px !important;
}

.kc-min-height--600{
    min-height: 600px !important;
    height: 600px !important;
}


.kc-p {
    height: 20px;
    width: 320px;
    padding: 0;
    overflow: hidden !important;
    position: relative;
    display: inline-block !important;
    margin: 0 5px 0 5px;
    text-align: center;
    text-decoration: none;
    text-overflow: ellipsis !important;
    white-space: nowrap;
    color: #000;
}

.overflow-ellipsis {
    text-overflow: ellipsis;
}

.kc-p-margin-zero > p{
    margin: 0px !important;;
}

.kc-title-position-design-1 {
    margin-top: 145px;
    margin-left: 170px;
}

.grecaptcha-badge{
    bottom: 90px !important;
}
.kc-font-size-13 {
    font-size: 13px !important;
}

.kc-link-with-bold {
    color: var(--color-primary);
    font-weight: bold !important;
}

.kc-text-center-and-bold {
    text-align: center;
    font-weight: bold;
}

@media screen and (max-width: 800px){
    .sidebar-navigation {
        display: none;
        visibility: hidden;
        clear: both;
    }
    .data_main {
        flex: 1 1;
        max-width: 100%;
        margin-left: 5px;
        padding: 0 0 30px 0px;
        overflow-x: scroll;
    }
}
/*ScrollToTop==================*/
.w-6 {
    width: 1.5rem;
}
.h-6 {
    height: 1.5rem;
}
.flex {
    display: flex;
}
.mx-auto {
    margin-left: auto;
    margin-right: auto;
}

.scroll-to-top-custom {
    background-color: white;
    right: 40px;
    bottom: 40px;
    position: fixed;
    z-index: 2;
    cursor: pointer;
    border-radius: 7px;
    width: 40px;
    height: 40px;
    transition: opacity 1s ease-in-out;
    box-shadow: 0 9px 25px 0 rgba(132, 128, 177, 0.28);
    border: none;
    outline: none;
    -webkit-appearance: button;
    background-image: none;
    text-transform: none;
    font-family: inherit;
    font-size: 100%;
    line-height: inherit;
    color: inherit;
    margin: 0 !important;
    padding: 0 !important;
}

.scroll-to-top-custom:active {
    transform: matrix(0.95, 0, 0, 0.95, 0, 0);
}

.stroke-th-accent-light {
    stroke: var(--color-primary);
}
.fill-th-accent-light {
    fill: var(--color-primary);
}
/*ScrollToTop==================*/